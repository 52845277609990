import {Config, schema} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {integrationCustomerSettings} from "shared-integration-customer-settings";

type RoleIdentifier = (typeof schema.definitions.roles.enum)[number];

function getRoleIdentifiers(role: Role): Set<RoleIdentifier> {
  const identifiers = new Set<RoleIdentifier>();
  if (role.manager) {
    identifiers.add("manager");
  }
  if (role.seniorMachineOperator) {
    identifiers.add("senior_machine_operator");
  }
  if (role.projectManager) {
    identifiers.add("project_manager");
  }
  if (role.machineOperator) {
    identifiers.add("machine_operator");
  }
  if (role.mechanic) {
    identifiers.add("mechanic");
  }
  if (role.jobber) {
    identifiers.add("jobber");
  }
  return identifiers;
}

export function workplaceChangeAllowedBySync(customerSettings: Config): boolean {
  const {
    brugerdataSync,
    c5Sync,
    economicEnableWorkplaceImport,
    economicInvoicePerWorkplace,
    economicSync,
    economicUploadWorkPlaces,
    navSync,
    navSyncProfile,
    unicontaSync,
  } = customerSettings;
  if (unicontaSync || navSync || brugerdataSync || navSyncProfile === "bejstrupm") {
    return true;
  } else if (c5Sync) {
    return false;
  } else if (economicSync) {
    if (economicUploadWorkPlaces) {
      return true;
    } else {
      return !economicEnableWorkplaceImport && !economicInvoicePerWorkplace;
    }
  } else {
    const {integrationStatus} = integrationCustomerSettings(customerSettings);
    console.assert(!integrationStatus.hasActiveEconomySystemIntegration);
    return true;
  }
}

export function allowLocationCreate(
  customerSettings: Config,
  role: Readonly<Role> | null,
): boolean {
  if (role === null) {
    return false;
  }
  const roleIdentifiers = getRoleIdentifiers(role);
  return customerSettings.rolesMayCreateLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );
}

export function allowLocationEdit(customerSettings: Config, role: Readonly<Role> | null): boolean {
  if (role === null) {
    return false;
  }
  const roleIdentifiers = getRoleIdentifiers(role);
  return customerSettings.rolesMayEditLocations.some((identifier) =>
    roleIdentifiers.has(identifier),
  );
}

export function allowWorkplaceCreate(
  customerSettings: Config,
  role: Readonly<Role> | null,
): boolean {
  return (
    workplaceChangeAllowedBySync(customerSettings) && allowLocationCreate(customerSettings, role)
  );
}

export function allowWorkplaceEdit(customerSettings: Config, role: Readonly<Role> | null): boolean {
  return (
    workplaceChangeAllowedBySync(customerSettings) && allowLocationEdit(customerSettings, role)
  );
}
