import {Config} from "@co-common-libs/config";
import {
  Customer,
  CustomerUrl,
  PatchUnion,
  Project,
  ProjectAccess,
  ProjectAccessSortOrdering,
  ProjectUrl,
  ResourceTypeUnion,
} from "@co-common-libs/resources";
import {formatDuration} from "@co-common-libs/resources-utils";
import {makeContainsPredicate} from "@co-common-libs/utils";
import {VerticalStackingFloatingActionButton} from "@co-frontend-libs/components";
import {
  AppState,
  PathTemplate,
  actions,
  getCurrentRole,
  getCurrentUserVisibleProjectArray,
  getCustomerLookup,
  getCustomerSettings,
  makeQueryParameterGetter,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import PlusIcon from "mdi-react/PlusIcon";
import React from "react";
import {FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {v4 as uuid} from "uuid";
import {ProjectCreateEditDialog, ProjectData} from "../create-edit-dialogs";
import {CustomerSelectCreateDialog} from "../customer-select-create-dialog";
import {PersistSortingStateProps, persistSortingState} from "../decorators/persist-sorting-state";
import {ProjectAccessIcon} from "../project-access-icon";
import {SORT_DIRECTIONS, SortTableHeaderColumn} from "../table-sort";

const PROJECT_NUMBER_COLUMN_WIDTH = 150;

const numberStringCompare = (a: string, b: string): number => {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: "base",
  });
};

const stringCompare = (a: string, b: string): number => {
  return a.localeCompare(b);
};

interface RowProps {
  access: ProjectAccess | null;
  alias?: string;
  customer: string;
  customerSettings: Config;
  distance?: number | null;
  name: string;
  onClick: (projectUrl: ProjectUrl) => void;
  projectNumber: string;
  remoteUrl: string;
  showAccessColumn: boolean;
  showAliasColumn?: boolean | undefined;
  showDistanceColumn: boolean;
  showTravelTimeColumn: boolean;
  travelTimeInMinutes?: number | null;
  url: ProjectUrl;
}

class Row extends PureComponent<RowProps> {
  static defaultProps = {
    access: "open",
  };

  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;

  @bind
  handleClick(): void {
    this.props.onClick(this.props.url);
  }

  render(): JSX.Element {
    const {formatNumber} = this.context;
    const {
      access,
      alias,
      customer,
      customerSettings,
      distance,
      name,
      projectNumber,
      remoteUrl,
      showAccessColumn,
      showAliasColumn,
      showDistanceColumn,
      showTravelTimeColumn,
      travelTimeInMinutes,
    } = this.props;
    const aliasColumn = showAliasColumn ? <TableCell>{alias}</TableCell> : null;
    const distanceColumn = showDistanceColumn ? (
      <TableCell
        style={{
          textAlign: "right",
        }}
      >
        {distance == null
          ? ""
          : formatNumber(distance, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 0,
            })}
      </TableCell>
    ) : null;
    const travelTimeColumn = showTravelTimeColumn ? (
      <TableCell style={{textAlign: "right"}}>
        {travelTimeInMinutes == null
          ? ""
          : formatDuration(customerSettings.durationFormat, travelTimeInMinutes)}
      </TableCell>
    ) : null;

    return (
      <TableRow style={{cursor: "pointer"}} onClick={this.handleClick}>
        <TableCell
          style={{
            textAlign: "right",
            width: PROJECT_NUMBER_COLUMN_WIDTH,
          }}
        >
          {projectNumber}
        </TableCell>
        {aliasColumn}
        <TableCell>{name}</TableCell>
        <TableCell>{customer}</TableCell>
        {distanceColumn}
        {travelTimeColumn}
        {showAccessColumn && (
          <TableCell>
            <ProjectAccessIcon project={{access, remoteUrl}} />
          </TableCell>
        )}
      </TableRow>
    );
  }
}

interface ProjectWithCustomerName extends Project {
  customerName: string;
}

interface ProjectsTableOwnProps {
  customerSettings: Config;
  entryList: readonly ProjectWithCustomerName[];
  filterString: string;
  onClick: (projectUrl: ProjectUrl) => void;
  showAccessColumn: boolean;
  showAliasColumn?: boolean;
  showDistanceColumn: boolean;
  showTravelTimeColumn: boolean;
}

type ProjectsTableProps = PersistSortingStateProps & ProjectsTableOwnProps;

class ProjectsTable extends PureComponent<ProjectsTableProps> {
  @bind
  handleRowClick(projectUrl: ProjectUrl): void {
    this.props.onClick(projectUrl);
  }

  render(): JSX.Element {
    const {
      customerSettings,
      entryList,
      showAccessColumn,
      showAliasColumn,
      showDistanceColumn,
      showTravelTimeColumn,
      sortDirection,
      sortKey,
    } = this.props;

    let comparatorValueMapper: (entry: ProjectWithCustomerName) => string;
    let comparator: (a: string, b: string) => number = stringCompare;
    if (!sortKey || sortKey === "projectNumber") {
      comparatorValueMapper = (entry) => entry.projectNumber;
      comparator = numberStringCompare;
    } else if (sortKey === "access") {
      comparatorValueMapper = (entry) =>
        ProjectAccessSortOrdering[entry.access ?? "open"].toString();
      comparator = numberStringCompare;
    } else if (sortKey === "alias") {
      comparatorValueMapper = (entry) => entry.alias;
    } else if (sortKey === "name") {
      comparatorValueMapper = (entry) => entry.name;
    } else if (sortKey === "customerName") {
      comparatorValueMapper = (entry) => entry.customerName;
    } else if (sortKey === "distance") {
      const distanceStoredDigits = 6;
      const distanceStoredDecimalPlaces = 2;
      comparatorValueMapper = (entry) =>
        entry.distanceInKm == null
          ? ""
          : entry.distanceInKm
              .toFixed(distanceStoredDecimalPlaces)
              .padStart(distanceStoredDigits, "0");
    } else if (sortKey === "travelTime") {
      const travelTimeMaxStoredDigits = 10;
      comparatorValueMapper = (entry) =>
        entry.travelTimeInMinutes == null
          ? ""
          : `${entry.travelTimeInMinutes}`.padStart(travelTimeMaxStoredDigits, "0");
    }
    let filteredProjectList = entryList;
    if (this.props.filterString) {
      const checkString = makeContainsPredicate(this.props.filterString);
      filteredProjectList = entryList.filter(({alias, customerName, name, projectNumber}) =>
        checkString(`${alias} ${customerName} ${name} ${projectNumber}`),
      );
    }
    const sortedProjectList = filteredProjectList
      .slice()
      .sort((a, b) => comparator(comparatorValueMapper(a), comparatorValueMapper(b)));
    if (sortDirection !== SORT_DIRECTIONS.ASC) {
      sortedProjectList.reverse();
    }

    const rows = sortedProjectList.map((project) => {
      const {
        access,
        alias,
        customerName,
        distanceInKm,
        name,
        projectNumber,
        remoteUrl,
        travelTimeInMinutes,
        url,
      } = project;
      return (
        <Row
          key={url}
          access={access}
          alias={alias}
          customer={customerName}
          customerSettings={customerSettings}
          distance={distanceInKm}
          name={name}
          projectNumber={projectNumber}
          remoteUrl={remoteUrl}
          showAccessColumn={showAccessColumn}
          showAliasColumn={showAliasColumn}
          showDistanceColumn={showDistanceColumn}
          showTravelTimeColumn={showTravelTimeColumn}
          travelTimeInMinutes={travelTimeInMinutes}
          url={url}
          onClick={this.handleRowClick}
        />
      );
    });
    const aliasSortableHeaderColumn = showAliasColumn ? (
      <SortTableHeaderColumn
        columnKey="alias"
        sortDirection={sortKey === "alias" ? sortDirection : undefined}
        onSortChange={this.props.onSortChange}
      >
        <FormattedMessage defaultMessage="Søgenavn" />
      </SortTableHeaderColumn>
    ) : null;
    const distanceSortableHeaderColumn = showDistanceColumn ? (
      <SortTableHeaderColumn
        columnKey="distance"
        sortDirection={sortKey === "distance" ? sortDirection : undefined}
        style={{
          textAlign: "right",
        }}
        onSortChange={this.props.onSortChange}
      >
        <FormattedMessage defaultMessage="Afstand (km)" />
      </SortTableHeaderColumn>
    ) : null;
    const travelTimeSortableHeaderColumn = showTravelTimeColumn ? (
      <SortTableHeaderColumn
        columnKey="travelTime"
        sortDirection={sortKey === "travelTime" ? sortDirection : undefined}
        style={{
          textAlign: "right",
        }}
        onSortChange={this.props.onSortChange}
      >
        <FormattedMessage defaultMessage="Rejsetid, en vej (timer:minutter)" />
      </SortTableHeaderColumn>
    ) : null;
    const accessColumn = showAccessColumn ? (
      <SortTableHeaderColumn
        columnKey="access"
        sortDirection={sortKey === "access" ? sortDirection : undefined}
        onSortChange={this.props.onSortChange}
      >
        <FormattedMessage defaultMessage="Adgang" />
      </SortTableHeaderColumn>
    ) : null;
    return (
      <div>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <SortTableHeaderColumn
                columnKey="projectNumber"
                sortDirection={sortKey === "projectNumber" ? sortDirection : undefined}
                style={{
                  textAlign: "right",
                  width: PROJECT_NUMBER_COLUMN_WIDTH,
                }}
                onSortChange={this.props.onSortChange}
              >
                {customerSettings.projectLabelVariant === "PROJECT" ? (
                  <FormattedMessage defaultMessage="Projektnr." />
                ) : (
                  <FormattedMessage defaultMessage="Sagsnr." />
                )}
              </SortTableHeaderColumn>
              {aliasSortableHeaderColumn}
              <SortTableHeaderColumn
                columnKey="name"
                sortDirection={sortKey === "name" ? sortDirection : undefined}
                onSortChange={this.props.onSortChange}
              >
                <FormattedMessage defaultMessage="Navn" />
              </SortTableHeaderColumn>
              <SortTableHeaderColumn
                columnKey="customerName"
                sortDirection={sortKey === "customerName" ? sortDirection : undefined}
                onSortChange={this.props.onSortChange}
              >
                <FormattedMessage defaultMessage="Kunde" />
              </SortTableHeaderColumn>
              {distanceSortableHeaderColumn}
              {travelTimeSortableHeaderColumn}
              {accessColumn}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </div>
    );
  }
}

const ProjectsTablePersistSorting: React.ComponentType<ProjectsTableOwnProps> =
  persistSortingState("ProjectsTable")(ProjectsTable);

interface ProjectsStateProps {
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: Config;
  projectArray: readonly Project[];
  q: string;
}

interface ProjectsDispatchProps {
  create: (instance: ResourceTypeUnion) => void;
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  update: (url: string, patch: PatchUnion) => void;
}

interface ProjectListOwnProps {
  customer?: Customer | undefined;
  filterByAccess?: ProjectAccess;
  onClick: (projectUrlURL: ProjectUrl) => void;
  showAccessColumn: boolean;
}

type ProjectsProps = ProjectsDispatchProps & ProjectsStateProps & ProjectListOwnProps;

class ProjectsList extends PureComponent<ProjectsProps> {
  state: {
    customerDialogOpen: boolean;
    projectCreateEditDialogOpen: boolean;
    selectedCustomer: Customer | undefined;
  } = {
    customerDialogOpen: false,
    projectCreateEditDialogOpen: false,
    selectedCustomer: undefined,
  };

  @bind
  handleRowClick(projectUrl: ProjectUrl): void {
    this.props.onClick(projectUrl);
  }

  @bind
  handleCustomerDialogCancel(): void {
    this.setState({customerDialogOpen: false});
  }

  @bind
  handleCustomerDialogOk(identifier: CustomerUrl): void {
    const selectedCustomer = this.props.customerLookup(identifier);
    this.setState({
      customerDialogOpen: false,
      projectCreateEditDialogOpen: true,
      selectedCustomer,
    });
  }

  @bind
  handleProjectCreateEditDialogCancel(): void {
    this.setState({projectCreateEditDialogOpen: false});
  }

  @bind
  handleProjectCreateOk(data: ProjectData): void {
    this.setState({projectCreateEditDialogOpen: false});
    const {selectedCustomer} = this.state;
    if (!selectedCustomer) {
      return;
    }
    const id = uuid();
    const url = instanceURL("project", id);
    const instance: Project = {
      ...data,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      c5_recid: 0,
      id,
      remoteUrl: "",
      url,
    };
    this.props.create(instance);
  }

  @bind
  handleFabButton(): void {
    if (this.props.customer) {
      this.handleCustomerDialogOk(this.props.customer.url);
    } else {
      this.setState({
        customerDialogOpen: true,
      });
    }
  }

  render(): JSX.Element {
    const {customerLookup, customerSettings, filterByAccess, projectArray} = this.props;
    const {customerDialogOpen, projectCreateEditDialogOpen, selectedCustomer} = this.state;

    const entries = projectArray
      .filter((project) => {
        const matchCustomer = this.props.customer?.url
          ? project.customer === this.props.customer?.url
          : true;
        const matchAccess = filterByAccess ? project.access === filterByAccess : true;
        return matchCustomer && matchAccess;
      })
      .map((project) => {
        const customer = customerLookup(project.customer);
        const customerName = (customer && customer.name) || "";
        return {...project, customerName};
      });
    const selectedCustomerUrl = selectedCustomer?.url;

    return (
      <>
        <ProjectsTablePersistSorting
          customerSettings={customerSettings}
          entryList={entries}
          filterString={this.props.q}
          showAccessColumn={this.props.showAccessColumn}
          showAliasColumn={!!customerSettings.showProjectAlias}
          showDistanceColumn={customerSettings.enableProjectDistance}
          showTravelTimeColumn={customerSettings.enableProjectTravelTime}
          onClick={this.handleRowClick}
        />
        {customerSettings.canAddProject ? (
          <VerticalStackingFloatingActionButton stackIndex={0} onClick={this.handleFabButton}>
            <PlusIcon />
          </VerticalStackingFloatingActionButton>
        ) : null}
        <CustomerSelectCreateDialog
          open={customerDialogOpen}
          onCancel={this.handleCustomerDialogCancel}
          onOk={this.handleCustomerDialogOk}
        />
        {selectedCustomerUrl ? (
          <ProjectCreateEditDialog
            data={selectedCustomerUrl}
            open={projectCreateEditDialogOpen}
            onCancel={this.handleProjectCreateEditDialogCancel}
            onOk={this.handleProjectCreateOk}
          />
        ) : null}
      </>
    );
  }
}

const ConnectedProjects = connect<
  ProjectsStateProps,
  ProjectsDispatchProps,
  ProjectListOwnProps,
  AppState
>(
  createStructuredSelector({
    currentRole: getCurrentRole,
    customerLookup: getCustomerLookup,
    customerSettings: getCustomerSettings,
    projectArray: getCurrentUserVisibleProjectArray,
    q: makeQueryParameterGetter("q"),
  }),
  {
    create: actions.create,
    go: actions.go,
    update: actions.update,
  },
)(ProjectsList);

export {ConnectedProjects as ProjectsList};
