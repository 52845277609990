import {useIntegrationCustomerSettings, useOrdersWithValidationRequired} from "integration-hooks";
import React, {useEffect} from "react";
import {ExecuteCreateSalesLinesForOrderProps, ExecuteSendOrder} from "./execute-send-order";

/**
 * If CO instance is configured to create sales lines for order (and therefore not on
 * approval of individual tasks), otherwise nothing happens.
 *
 * In the case of nothing happens - `onSuccess` will be triggered as soon as `execute` is true
 *
 * @param props
 * @constructor
 */
export const SendOrder = React.memo(function SendOrder(
  props: ExecuteCreateSalesLinesForOrderProps,
): React.JSX.Element | null {
  const {execute, onSuccess, order} = props;

  const {tasks} = useIntegrationCustomerSettings();

  const ordersWithValidationRequired = useOrdersWithValidationRequired();

  const createSalesLines = tasks.sendOnApprove && ordersWithValidationRequired.has(order.url);

  useEffect(() => {
    if (!createSalesLines && execute) {
      onSuccess();
    }
  }, [createSalesLines, execute, onSuccess]);

  return createSalesLines ? <ExecuteSendOrder {...props} /> : null;
});
