import {getCustomerSettings} from "@co-frontend-libs/redux";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {
  EconomySystemIntegrationCustomerSettings,
  integrationCustomerSettings,
} from "shared-integration-customer-settings";

export function useIntegrationCustomerSettings(): EconomySystemIntegrationCustomerSettings {
  const customerSettings = useSelector(getCustomerSettings);

  return useMemo(() => integrationCustomerSettings(customerSettings), [customerSettings]);
}
