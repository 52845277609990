import React, {useCallback, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {ResponsiveDialog} from "../responsive-dialog";

interface ModalPromptComponentProps<ReturnType> {
  onCancel: () => void;
  onOk: (result: ReturnType) => void;
}

type ModalComponent<ReturnType, PropsType> = React.ComponentType<
  PropsType & ModalPromptComponentProps<ReturnType>
>;

export class PromptCancelledError extends Error {
  constructor() {
    super("The prompt was cancelled");
    this.name = "PromptCancelledError";
  }
}

export const useModal = <ReturnType, PropsType>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ModalContent: ModalComponent<ReturnType, PropsType>,
  title: string = "",
): [
  React.ReactPortal,
  (
    additionalProps?: Omit<PropsType, keyof ModalPromptComponentProps<ReturnType>>,
  ) => Promise<ReturnType>,
] => {
  const [isOpen, setIsOpen] = useState(false);

  const additionalPropsRef = useRef<
    Omit<PropsType, keyof ModalPromptComponentProps<ReturnType>> | undefined
  >(undefined);
  const resolverRef = useRef<((value: ReturnType) => void) | null>(null);
  const rejecterRef = useRef<((error: PromptCancelledError) => void) | null>(null);

  const prompt = (
    additionalProps?: Omit<PropsType, keyof ModalPromptComponentProps<ReturnType>>,
  ): Promise<ReturnType> => {
    return new Promise<ReturnType>((resolve, reject) => {
      if (rejecterRef.current) {
        rejecterRef.current(new PromptCancelledError());
      }

      additionalPropsRef.current = additionalProps;
      resolverRef.current = resolve;
      rejecterRef.current = reject;
      setIsOpen(true);
    });
  };

  const onOk = useCallback((result: ReturnType) => {
    if (resolverRef.current) {
      resolverRef.current(result);
    }
    setIsOpen(false);
    resolverRef.current = null;
    rejecterRef.current = null;
  }, []);

  const onCancel = useCallback(() => {
    if (rejecterRef.current) {
      rejecterRef.current(new PromptCancelledError());
    }
    setIsOpen(false);
    resolverRef.current = null;
    rejecterRef.current = null;
  }, []);

  const modal = createPortal(
    <ResponsiveDialog hideActions open={isOpen} title={title} onCancel={onCancel}>
      <ModalContent
        onCancel={onCancel}
        onOk={onOk}
        {...(additionalPropsRef.current as PropsType)}
      />
    </ResponsiveDialog>,
    document.body,
    `modal-prompt-${ModalContent.displayName}`,
  );

  return [modal, prompt];
};
