import {Task} from "@co-common-libs/resources";
import {useIntegrationCustomerSettings, useOrderValidationRequiredForTask} from "integration-hooks";
import React, {useEffect} from "react";
import {ExecuteCreateSalesLinesForTaskProps, ExecuteSendTask} from "./execute-send-task";

export interface CreateSalesLinesForTaskProps extends ExecuteCreateSalesLinesForTaskProps {
  tasks: readonly Pick<Task, "order" | "url" | "workType">[];
}

/**
 * If CO instance is configured to create sales lines for individual tasks,
 * otherwise nothing happens.
 *
 * In the case of nothing happens - `onSuccess` will be triggered as soon as `execute` is true
 *
 * @param props
 * @constructor
 */
export const SendTask = React.memo(function SendTask(
  props: CreateSalesLinesForTaskProps,
): React.JSX.Element | null {
  const {execute, onSuccess, tasks} = props;

  const {tasks: taskSettings} = useIntegrationCustomerSettings();

  const orderValidationRequiredForTask = useOrderValidationRequiredForTask();

  const sendTask = taskSettings.sendOnApprove && !tasks.some(orderValidationRequiredForTask);

  useEffect(() => {
    if (!sendTask && execute) {
      onSuccess();
    }
  }, [sendTask, execute, onSuccess]);

  return sendTask ? <ExecuteSendTask {...props} /> : null;
});
