import {Config} from "@co-common-libs/config";
import {
  Customer,
  PatchUnion,
  PriceGroup,
  PriceGroupItem,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  ResourceTypeUnion,
  RoutePlanTask,
  RoutePlanTaskActivityOption as RoutePlanTaskActivityOptionResource,
  RoutePlanTaskResult as RoutePlanTaskResultResource,
  Unit,
  UnitUrl,
  WorkType,
} from "@co-common-libs/resources";
import {
  getUnitString,
  sortPriceItemUseListByLineNumber,
  sortPriceItemUseListByRemoteURL,
} from "@co-common-libs/resources-utils";
import {setFind, setSome, sortByOrderMember} from "@co-common-libs/utils";
import {DecimalField} from "@co-frontend-libs/components";
import {Checkbox, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import React from "react";
import {FormattedMessage, FormattedNumber, IntlContext, defineMessages} from "react-intl";
import {integrationCustomerSettings} from "shared-integration-customer-settings";
import {v4 as uuid} from "uuid";

const messages = defineMessages({
  quoted: {
    defaultMessage: "Standardværdi",
    id: "route-plan-task.label.quoted",
  },
});

const CHECKBOX_COLUMN_WIDTH = 48;
const UNIT_COLUMN_WIDTH = 96;
const PRICE_COLUMN_WIDTH = 96;
const QUOTED_COLUMN_WIDTH = 128;
const VISIBLE_COLUMN_WIDTH = 148;

interface RoutePlanTaskActivityOptionProps {
  currentlySelected: boolean;
  customerSettings: Config;
  onSelectedChange: (priceGroupURL: PriceGroupUrl, newSelected: boolean) => void;
  priceGroup: PriceGroup;
}

class RoutePlanTaskActivityOption extends PureComponent<RoutePlanTaskActivityOptionProps> {
  @bind
  handleSelectedChange(_event: React.ChangeEvent<HTMLInputElement>, _checked: boolean): void {
    const {currentlySelected, onSelectedChange, priceGroup} = this.props;
    const newSelected = !currentlySelected;
    onSelectedChange(priceGroup.url, newSelected);
  }
  render(): JSX.Element {
    const {currentlySelected, customerSettings, priceGroup} = this.props;
    return (
      <TableRow>
        <TableCell
          style={{
            paddingLeft: 12,
            paddingRight: 12,
            width: CHECKBOX_COLUMN_WIDTH,
          }}
        >
          <Checkbox checked={currentlySelected} onChange={this.handleSelectedChange} />
        </TableCell>
        <TableCell>{priceGroup.identifier}</TableCell>
        <TableCell>{priceGroup.name}</TableCell>
        <TableCell>{priceGroup.identifier}</TableCell>
        <TableCell style={{width: QUOTED_COLUMN_WIDTH}} />
        <TableCell style={{width: UNIT_COLUMN_WIDTH}} />
        {customerSettings.overviewShowPrices ? (
          <TableCell style={{width: PRICE_COLUMN_WIDTH}} />
        ) : null}
        <TableCell style={{width: VISIBLE_COLUMN_WIDTH}} />
      </TableRow>
    );
  }
}

interface RoutePlanTaskResultProps {
  customerSettings: Config;
  priceItem: PriceItem;
  routePlanTaskResult: RoutePlanTaskResultResource;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
}

class RoutePlanTaskResult extends PureComponent<RoutePlanTaskResultProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleQuotedChange(value: number | null): void {
    const {routePlanTaskResult, update} = this.props;
    update(routePlanTaskResult.url, [{member: "quoted", value}]);
  }
  render(): JSX.Element {
    const {formatMessage} = this.context;
    const {customerSettings, priceItem, routePlanTaskResult, unitLookup} = this.props;
    const {quoted} = routePlanTaskResult;
    const relevantForExecution =
      priceItem.relevantForExecution != null ? priceItem.relevantForExecution : true;
    const backendMaxDigits = 10;
    const backendDecimalPlaces = 3;
    const integerPartDigits = backendMaxDigits - backendDecimalPlaces;
    const decimalPlaces = customerSettings.transportLogDecimals;
    console.assert(decimalPlaces <= backendDecimalPlaces);
    const maxDigits = integerPartDigits + decimalPlaces;
    const {price} = priceItem;
    const formattedPrice =
      typeof price === "number" ? (
        <FormattedNumber maximumFractionDigits={2} minimumFractionDigits={2} value={price} />
      ) : price === null ? (
        "-"
      ) : null;

    return (
      <TableRow>
        <TableCell
          style={{
            width: CHECKBOX_COLUMN_WIDTH,
          }}
        />
        <TableCell />
        <TableCell>{priceItem.name}</TableCell>
        <TableCell />
        <TableCell style={{width: QUOTED_COLUMN_WIDTH}}>
          <DecimalField
            decimalPlaces={decimalPlaces}
            label={formatMessage(messages.quoted)}
            margin="dense"
            maxDigits={maxDigits}
            value={quoted}
            onChange={this.handleQuotedChange}
          />
        </TableCell>
        <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
          {getUnitString(priceItem, unitLookup)}
        </TableCell>
        {customerSettings.overviewShowPrices ? (
          <TableCell style={{width: PRICE_COLUMN_WIDTH}}>{formattedPrice}</TableCell>
        ) : null}
        <TableCell style={{width: VISIBLE_COLUMN_WIDTH}}>
          <Checkbox disabled checked={relevantForExecution} />
        </TableCell>
      </TableRow>
    );
  }
}

interface RoutePlanTaskActivitySelectionContentProps {
  create: (instance: ResourceTypeUnion) => void;
  customerSettings: Config;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceGroupSet: ReadonlySet<PriceGroup>;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  remove: (url: string) => void;
  routePlanTask: RoutePlanTask;
  routePlanTaskActivityOptionSet: ReadonlySet<RoutePlanTaskActivityOptionResource>;
  routePlanTaskResultSet: ReadonlySet<RoutePlanTaskResultResource>;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
}

const sortPriceGroupItemListByManualOrder = (
  priceGroupItemSet: readonly PriceGroupItem[],
): PriceGroupItem[] => sortByOrderMember(priceGroupItemSet);

class RoutePlanTaskActivitySelectionContent extends PureComponent<RoutePlanTaskActivitySelectionContentProps> {
  @bind
  handleSelectedChange(priceGroupURL: PriceGroupUrl, selected: boolean): void {
    const {
      create,
      priceGroupLookup,
      priceItemLookup,
      remove,
      routePlanTask,
      routePlanTaskActivityOptionSet,
      routePlanTaskResultSet,
    } = this.props;
    const routePlanTaskURL = routePlanTask.url;
    if (selected) {
      if (
        setSome(
          routePlanTaskActivityOptionSet,
          (routePlanTaskActivityOption) => routePlanTaskActivityOption.activity === priceGroupURL,
        )
      ) {
        return;
      }
      const id = uuid();
      const url = instanceURL("routePlanTaskActivityOption", id);
      const obj: RoutePlanTaskActivityOptionResource = {
        activity: priceGroupURL,
        id,
        routePlanTask: routePlanTaskURL,
        url,
      };
      create(obj);
      const priceGroup = priceGroupLookup(priceGroupURL);
      if (priceGroup && priceGroup.priceGroupItemSet) {
        let priceItemGroupList: PriceGroupItem[];
        const {navSync} = this.props.customerSettings;
        const {priceItems} = integrationCustomerSettings(this.props.customerSettings);
        if (priceItems.allowManualPriceGroupPriceItemOrdering) {
          priceItemGroupList = sortPriceGroupItemListByManualOrder(priceGroup.priceGroupItemSet);
        } else if (navSync) {
          priceItemGroupList = sortPriceItemUseListByLineNumber(
            priceGroup.priceGroupItemSet,
            priceItemLookup,
          );
        } else {
          priceItemGroupList = sortPriceItemUseListByRemoteURL(
            priceGroup.priceGroupItemSet,
            priceItemLookup,
          );
        }

        priceItemGroupList.forEach((priceGroupItem, index) => {
          const priceItemURL = priceGroupItem.priceItem;

          const priceItem = priceItemLookup(priceItemURL);
          if (priceItem && priceItem.active) {
            const resultID = uuid();
            const resultURL = instanceURL("routePlanTaskResult", resultID);
            const resultObj = {
              activityOption: url,
              id: resultID,
              order: index,
              quoted: null,
              routePlanTask: routePlanTaskURL,
              specification: priceItemURL,
              url: resultURL,
            };
            create(resultObj);
          }
        });
      }
    } else {
      const routePlanTaskActivityOption = setFind(
        routePlanTaskActivityOptionSet,
        (entry) => entry.activity === priceGroupURL,
      );
      if (!routePlanTaskActivityOption) {
        return;
      }
      const {url} = routePlanTaskActivityOption;
      routePlanTaskResultSet.forEach((routePlanTaskResult) => {
        if (routePlanTaskResult.activityOption === url) {
          remove(routePlanTaskResult.url);
        }
      });
      remove(url);
    }
  }
  render(): JSX.Element {
    const {
      customerSettings,
      priceGroupSet,
      priceItemLookup,
      routePlanTaskActivityOptionSet,
      routePlanTaskResultSet,
      unitLookup,
      update,
    } = this.props;
    const activityOptionPerPriceGroup = new Map(
      Array.from(routePlanTaskActivityOptionSet).map((routePlanTaskActivityOption) => [
        routePlanTaskActivityOption.activity,
        routePlanTaskActivityOption,
      ]),
    );
    const entries: JSX.Element[] = [];
    const routePlanTaskResultList = _.sortBy(
      Array.from(routePlanTaskResultSet),
      (routePlanTaskResult) => {
        const priceItemURL = routePlanTaskResult.specification;
        const priceItem = priceItemLookup(priceItemURL);
        return priceItem ? priceItem.name : "";
      },
    );
    _.sortBy(Array.from(priceGroupSet), (priceGroup) => priceGroup.identifier).forEach(
      (priceGroup) => {
        const {url} = priceGroup;
        const activityOption = activityOptionPerPriceGroup.get(url);
        entries.push(
          <RoutePlanTaskActivityOption
            key={url}
            currentlySelected={!!activityOption}
            customerSettings={customerSettings}
            priceGroup={priceGroup}
            onSelectedChange={this.handleSelectedChange}
          />,
        );
        if (activityOption) {
          const activityOptionURL = activityOption.url;
          _.sortBy(
            routePlanTaskResultList,
            (routePlanTaskResult) => routePlanTaskResult.order,
          ).forEach((routePlanTaskResult) => {
            if (routePlanTaskResult.activityOption === activityOptionURL) {
              const priceItemURL = routePlanTaskResult.specification;
              const priceItem = priceItemLookup(priceItemURL);
              if (!priceItem) {
                return;
              }
              entries.push(
                <RoutePlanTaskResult
                  key={`${url}-${routePlanTaskResult.url}`}
                  customerSettings={this.props.customerSettings}
                  priceItem={priceItem}
                  routePlanTaskResult={routePlanTaskResult}
                  unitLookup={unitLookup}
                  update={update}
                />,
              );
            }
          });
        }
      },
    );
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: CHECKBOX_COLUMN_WIDTH}} />
            <TableCell>
              <FormattedMessage defaultMessage="Variant-ID" id="route-plan-task.label.variant-id" />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Navn" id="route-plan-task.label.name" />
            </TableCell>
            <TableCell>
              <FormattedMessage defaultMessage="Variant-ID" id="route-plan-task.label.variant-id" />
            </TableCell>
            <TableCell style={{width: QUOTED_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Antal" id="route-plan-task.label.count" />
            </TableCell>
            <TableCell style={{width: UNIT_COLUMN_WIDTH}}>
              <FormattedMessage defaultMessage="Enhed" id="route-plan-task.label.unit" />
            </TableCell>
            {customerSettings.overviewShowPrices ? (
              <TableCell style={{width: PRICE_COLUMN_WIDTH}}>
                <FormattedMessage
                  defaultMessage="Pris (kr)"
                  id="route-plan-task.table-header.price"
                />
              </TableCell>
            ) : null}
            <TableCell style={{width: VISIBLE_COLUMN_WIDTH}}>
              <FormattedMessage
                defaultMessage="Synlig ved udførsel"
                id="route-plan-task.label.visible-during-execution"
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{entries}</TableBody>
      </Table>
    );
  }
}

interface RoutePlanTaskActivitySelectionContainerProps {
  create: (instance: ResourceTypeUnion) => void;
  customer?: Customer | undefined;
  customerSettings: Config;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  remove: (url: string) => void;
  routePlanTask: RoutePlanTask;
  routePlanTaskActivityOptionSet: ReadonlySet<RoutePlanTaskActivityOptionResource>;
  routePlanTaskResultSet: ReadonlySet<RoutePlanTaskResultResource>;
  unitLookup: (url: UnitUrl) => Unit | undefined;
  update: (url: string, patch: PatchUnion) => void;
  workType?: WorkType | undefined;
}

class RoutePlanTaskActivitySelectionContainer extends PureComponent<RoutePlanTaskActivitySelectionContainerProps> {
  render(): JSX.Element {
    const {
      create,
      customer,
      priceGroupLookup,
      priceItemLookup,
      remove,
      routePlanTask,
      routePlanTaskActivityOptionSet,
      routePlanTaskResultSet,
      unitLookup,
      update,
      workType,
    } = this.props;
    const workTypePriceGroupURLs = workType
      ? new Set(workType.pricegroups)
      : new Set<PriceGroupUrl>();
    const customerURL = customer ? customer.url : null;
    let workTypeCustomerPriceGroupSet = new Set<PriceGroup>();
    let worktypeCommonPriceGroupSet = new Set<PriceGroup>();
    workTypePriceGroupURLs.forEach((priceGroupURL) => {
      const priceGroup = priceGroupLookup(priceGroupURL);
      if (!priceGroup || !priceGroup.active) {
        return;
      }
      const priceGroupCustomerURLs = priceGroup.customers;
      if (!priceGroupCustomerURLs.length) {
        worktypeCommonPriceGroupSet = worktypeCommonPriceGroupSet.add(priceGroup);
      } else if (customerURL && priceGroupCustomerURLs.includes(customerURL)) {
        workTypeCustomerPriceGroupSet = workTypeCustomerPriceGroupSet.add(priceGroup);
      }
    });
    const priceGroupSet = !workTypeCustomerPriceGroupSet.size
      ? worktypeCommonPriceGroupSet
      : workTypeCustomerPriceGroupSet;
    routePlanTaskActivityOptionSet.forEach((routePlanTaskActivityOption) => {
      const priceGroupURL = routePlanTaskActivityOption.activity;
      if (!setSome(priceGroupSet, (priceGroup) => priceGroup.url === priceGroupURL)) {
        const priceGroup = priceGroupLookup(priceGroupURL);
        if (priceGroup) {
          priceGroupSet.add(priceGroup);
        }
      }
    });
    return (
      <RoutePlanTaskActivitySelectionContent
        create={create}
        customerSettings={this.props.customerSettings}
        priceGroupLookup={priceGroupLookup}
        priceGroupSet={priceGroupSet}
        priceItemLookup={priceItemLookup}
        remove={remove}
        routePlanTask={routePlanTask}
        routePlanTaskActivityOptionSet={routePlanTaskActivityOptionSet}
        routePlanTaskResultSet={routePlanTaskResultSet}
        unitLookup={unitLookup}
        update={update}
      />
    );
  }
}

export {RoutePlanTaskActivitySelectionContainer as RoutePlanTaskActivitySelection};
