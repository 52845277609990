import {Project, ProjectUrl, urlToId} from "@co-common-libs/resources";
import {TaskArchiveData} from "@co-common-libs/resources-utils";
import {notUndefined} from "@co-common-libs/utils";
import {DateField, TrimTextField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {QueryParameters} from "@co-frontend-libs/routing-sync-history";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  PaginationPageSize,
  SortingState,
  TableDefaults,
  TaskTableColumnID,
  buildArchiveDataUrl,
  useArchiveSearchState,
  useTableWithPagination,
} from "app-components";
import {useFetchGet} from "app-utils";
import bowser from "bowser";
import clsx from "clsx";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {ArchiveTaskTable} from "../task-list/task-archive/archive-task-table";

const mobile = bowser.tablet || bowser.mobile;

export const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    mobilePadding: {padding: "1em 11px 1em 11px"},
    pcPadding: {padding: "1em"},
  });
});

const taskTableDefaults: TableDefaults<TaskTableColumnID> = {
  defaultRowsPerPage: PaginationPageSize.SMALL,
  defaultSortDirection: "ASC",
  defaultSortKey: "status",
};

interface ProjectTaskTableProps {
  project: Pick<Project, "url">;
}

const tableSaveIdentifier = "ProjectTaskArchiveTable";

export const ProjectTaskTable = React.memo(function ProjectTaskTable(
  props: ProjectTaskTableProps,
): JSX.Element {
  const {project} = props;
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [fetchFirstDate, setfetchFirstDate] = useState(true);

  const {
    fromDate,
    handleFromDateBlur,
    handlePeriodStartSelectedInDialog,
    searchPerformedWithCurrentCriteria,
    setSearchPerformedWithCurrentCriteria,
    setTextSearch,
    setToDate,
    textSearch,
    toDate,
  } = useArchiveSearchState();

  const [displayFromDate, setDisplayFromDate] = useState<string | null>(null);

  const [fetchData, fetchDataCancel, reponse, fetching, fetchError] = useFetchGet<{
    count: number;
    data: TaskArchiveData[];
  }>();

  const [page, rowsPerPage, sortBy, sortDirection, setPage, setRowsPerPage, toggleSortingKey] =
    useTableWithPagination(
      tableSaveIdentifier,
      tableSaveIdentifier,
      taskTableDefaults.defaultSortKey,
      taskTableDefaults.defaultSortDirection,
      taskTableDefaults.defaultRowsPerPage,
      null,
    );

  const sortingState: SortingState<TaskTableColumnID> = useMemo(() => {
    return {sortDirection, sortKey: sortBy};
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (reponse && fetchFirstDate) {
      setSearchPerformedWithCurrentCriteria(true);
      if (reponse.data.length > 0) {
        const earliestDate =
          _.min(reponse.data.map((task) => task.date).filter(notUndefined)) || null;
        setDisplayFromDate(earliestDate);
      }
      setfetchFirstDate(false);
    }
  }, [reponse, setSearchPerformedWithCurrentCriteria, fetchFirstDate]);

  useEffect(() => {
    fetchDataCancel();
    setSearchPerformedWithCurrentCriteria(false);
  }, [fromDate, toDate, textSearch, fetchDataCancel, setSearchPerformedWithCurrentCriteria]);

  const doFetch = useCallback(
    (
      fetchFromDate: string | null,
      fetchToDate: string | null,
      fetchSortingState: SortingState<TaskTableColumnID>,
      fetchPage: number,
      fetchRowsPerPage: number,
      fetchTextSearch: string,
      projectUrl: ProjectUrl,
    ) => {
      if (!fetchFromDate || !fetchToDate || fetchFromDate <= fetchToDate) {
        const archiveUrl = buildArchiveDataUrl({
          archiveResource: "task",
          fetchFromDate,
          fetchPage,
          fetchRowsPerPage,
          fetchSortingState,
          fetchTextSearch,
          fetchToDate,
          project: projectUrl,
        });
        fetchData(archiveUrl);
      }
    },
    [fetchData],
  );

  const handleFetchClick = useCallback(() => {
    doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch, project.url);
  }, [doFetch, fromDate, toDate, sortingState, page, rowsPerPage, textSearch, project.url]);

  const handleSubmitOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleFetchClick();
      }
    },
    [handleFetchClick],
  );

  useEffect(() => {
    if (searchPerformedWithCurrentCriteria) {
      handleFetchClick();
    }
  }, [sortingState, handleFetchClick, searchPerformedWithCurrentCriteria]);

  useEffect(() => {
    doFetch(null, null, sortingState, page, rowsPerPage, "", project.url);
    // We only want to autostart search on initial mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage);
      doFetch(fromDate, toDate, sortingState, newPage, rowsPerPage, textSearch, project.url);
    },
    [doFetch, fromDate, rowsPerPage, setPage, sortingState, textSearch, toDate, project.url],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const rows = parseInt(event.target.value);
      setPage(0);
      setRowsPerPage(rows);
      doFetch(fromDate, toDate, sortingState, 0, rows, textSearch, project.url);
    },
    [doFetch, fromDate, setPage, setRowsPerPage, sortingState, textSearch, toDate, project.url],
  );

  const navigateToTask = useCallback(
    (taskURL: string, queryParameters?: QueryParameters) => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}, queryParameters));
    },
    [dispatch],
  );

  const navigateToTaskPhotoTab = useCallback(
    (taskURL: string) => {
      navigateToTask(taskURL, {tab: "photos"});
    },
    [navigateToTask],
  );

  let errorText: string | undefined;

  if (fetchError) {
    if (fetchError instanceof NetworkError) {
      errorText = intl.formatMessage({
        defaultMessage: "Kunne ikke kontakte server",
      });
    } else if (fetchError instanceof StatusError) {
      errorText = intl.formatMessage({defaultMessage: "Serverfejl"});
    } else {
      errorText = intl.formatMessage({defaultMessage: "Ukendt fejl"});
    }
  }

  return (
    <div
      className={clsx({
        [classes.mobilePadding]: mobile,
        [classes.pcPadding]: !mobile,
      })}
    >
      <Card>
        <CardContent>
          <FormattedMessage defaultMessage="Periode" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                referenceDate={toDate || undefined}
                value={displayFromDate || null}
                yearSuggestions="DATE_BEFORE"
                onBlur={handleFromDateBlur}
                onChange={setDisplayFromDate}
                onKeyDown={handleSubmitOnEnter}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Til"})}
                margin="dense"
                minDate={fromDate || undefined}
                referenceDate={fromDate || undefined}
                value={toDate || null}
                yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
                onChange={setToDate}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
            <Grid item xs={12}>
              <TrimTextField
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fritekst"})}
                margin="dense"
                value={textSearch}
                variant="outlined"
                onChange={setTextSearch}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={(fromDate && toDate && fromDate > toDate) || fetching}
            variant="contained"
            onClick={handleFetchClick}
          >
            <FormattedMessage defaultMessage="Søg" />
          </Button>
        </CardActions>
      </Card>
      {fetching ? (
        <div style={{marginTop: "1em", textAlign: "center"}}>
          <CircularProgress />
        </div>
      ) : null}
      {reponse ? (
        <div style={{marginTop: "1em"}}>
          <ArchiveTaskTable
            count={reponse.data.length}
            data={reponse.data}
            page={page}
            rowsPerPage={rowsPerPage}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onClick={navigateToTask}
            onHeaderClick={toggleSortingKey}
            onPageChange={handleChangePage}
            onPhotoClick={navigateToTaskPhotoTab}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
      {errorText ? (
        <Card style={{marginTop: "1em"}}>
          <CardContent>
            <Typography color="error">{errorText}</Typography>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
});
