import {Task, urlToId} from "@co-common-libs/resources";
import {notNull, notUndefined} from "@co-common-libs/utils";
import {ResponsiveDialog} from "@co-frontend-libs/components";
import {createHref, getCustomerSettings, getTaskLookup} from "@co-frontend-libs/redux";
import {
  colorMap,
  useCallWithFalse,
  useCallWithTrue,
  useResettingState,
} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {APPROVE_ECONOMIC_CUSTOMER_ACTION, ErrorAction, ErrorEntry, InlinedTask} from "app-utils";
import {SendTask} from "feat-send-task-data";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {EconomicCustomerApproveOrChangeDialog} from "./customer-dialogs/economic";
import {ErrorList} from "./error-list";

// error actions that we are prepared to handle e.g. the UI displays a clickable pencil icon
const onActionWhitelist: readonly ErrorAction[] = [APPROVE_ECONOMIC_CUSTOMER_ACTION];

interface ValidationDialogProps {
  onCancel: () => void;
  onOk: (taskList: readonly InlinedTask[]) => void;
  open: boolean;
  someIncomplete?: boolean;
  taskIssueMap?:
    | ReadonlyMap<string, {errors: readonly ErrorEntry[]; warnings: readonly ErrorEntry[]}>
    | undefined;
  taskList?: readonly InlinedTask[] | undefined;
}

export const ValidationDialog = React.memo(function ValidationDialog(
  props: ValidationDialogProps,
): React.JSX.Element {
  const {onCancel, onOk, open, someIncomplete, taskIssueMap, taskList} = props;
  const handleOk = useCallback(() => {
    if (taskList) {
      onOk(taskList);
    }
  }, [onOk, taskList]);

  const [customerApprovalDialogOpen, setCustomerApprovalDialogOpen] = useState(false);
  const setCustomerApprovalDialogOpenFalse = useCallWithFalse(setCustomerApprovalDialogOpen);
  const [taskWithCustomerPendingApproval, setTaskWithCustomerPendingApproval] =
    useResettingState<Task | null>(null, open);

  const [hasErrors, setHasErrors] = useResettingState(false, open);
  const [dialogContent, setDialogContent] = useState<JSX.Element>();
  const intl = useIntl();

  const taskLookup = useSelector(getTaskLookup);
  const customerSettings = useSelector(getCustomerSettings);

  const handleErrorAction = useCallback(
    (inlineTask: InlinedTask, action: ErrorAction) => {
      if (action === APPROVE_ECONOMIC_CUSTOMER_ACTION) {
        const task = taskLookup(inlineTask.url);
        if (task) {
          setTaskWithCustomerPendingApproval(task);
          setCustomerApprovalDialogOpen(true);
        }
      }
    },
    [setTaskWithCustomerPendingApproval, taskLookup],
  );

  const getHandleErrorActionForTask = useMemo(
    () => _.memoize((task: InlinedTask) => handleErrorAction.bind(null, task)),
    [handleErrorAction],
  );

  useEffect(() => {
    if (open && taskList && taskIssueMap && !customerApprovalDialogOpen) {
      let hasTaskWithError = false;
      const result = taskList
        .map((task) => {
          const taskURL = task.url;
          const taskData = taskIssueMap.get(taskURL);
          const errors = taskData ? taskData.errors : [];
          if (!hasTaskWithError) {
            hasTaskWithError = !!errors.length;
          }

          let errorBlock: JSX.Element | undefined;
          if (errors.length) {
            errorBlock = (
              <div style={{color: colorMap.ERROR}}>
                <FormattedMessage defaultMessage="Fejl:" tagName="h4" />
                <ErrorList
                  errors={errors}
                  onAction={getHandleErrorActionForTask(task)}
                  onActionWhitelist={onActionWhitelist}
                />
              </div>
            );
          }
          const warnings = taskData ? taskData.warnings : [];
          let warningBlock: JSX.Element | undefined;
          if (warnings.length) {
            warningBlock = (
              <div style={{color: colorMap.WARNING}}>
                <FormattedMessage defaultMessage="Advarsler:" tagName="h4" />
                <ErrorList errors={warnings} />
              </div>
            );
          }
          const {invoiceNote} = task;
          let notesBlock;
          if (invoiceNote) {
            notesBlock = <div>F: {invoiceNote}</div>;
          }
          if (!warningBlock && !errorBlock && !notesBlock) {
            return null;
          }
          const {workType} = task;
          const workTypeName = workType ? workType.name : undefined;
          let customerName;
          if (task.order) {
            const {order} = task;
            const customer = order ? order.customer : undefined;
            customerName = customer ? customer.name : undefined;
          } else {
            customerName = intl.formatMessage({defaultMessage: "Intern"});
          }
          return (
            <div key={taskURL} style={{paddingBottom: "1em"}}>
              <h3>
                <a href={createHref("/task/:id", {id: urlToId(task.url)})}>{workTypeName}</a>
              </h3>
              <h4>{customerName}</h4>
              {errorBlock}
              {warningBlock}
              {notesBlock}
            </div>
          );
        })
        .filter(notNull);

      setHasErrors(hasTaskWithError);

      setDialogContent(
        <>
          {someIncomplete ? (
            <div style={{color: colorMap.WARNING}}>
              <FormattedMessage defaultMessage="Der findes uafsluttede opgaver på dagen - disse godkendes ikke" />
            </div>
          ) : null}
          {result.length ? (
            result
          ) : (
            <FormattedMessage defaultMessage="Alle opgaver kan godkendes uden advarsler." />
          )}
        </>,
      );
    }
  }, [
    customerApprovalDialogOpen,
    getHandleErrorActionForTask,
    hasErrors,
    intl,
    open,
    setHasErrors,
    someIncomplete,
    taskIssueMap,
    taskList,
  ]);

  const [createSalesLines, setCreateSalesLines] = useResettingState(false, open);
  const setCreateSalesLinesTrue = useCallWithTrue(setCreateSalesLines);

  const createSalesLinesForTasks: Task[] = useMemo(
    () =>
      taskList && !hasErrors
        ? taskList
            .map(({url}) => url)
            .map(taskLookup)
            .filter(notUndefined)
        : [],
    [hasErrors, taskList, taskLookup],
  );

  // TODO(mr): make create sales lines for multiple tasks work
  return (
    <>
      {customerSettings.economicSync && taskWithCustomerPendingApproval && (
        <EconomicCustomerApproveOrChangeDialog
          allowChangeCurrentTaskOnly
          open={customerApprovalDialogOpen}
          task={taskWithCustomerPendingApproval}
          onCancel={setCustomerApprovalDialogOpenFalse}
          onSuccess={setCustomerApprovalDialogOpenFalse}
        />
      )}
      <SendTask
        execute={createSalesLines}
        tasks={createSalesLinesForTasks}
        onErrorDialogDismiss={onCancel}
        onSuccess={handleOk}
      />
      <ResponsiveDialog
        autoFocusCancel={hasErrors}
        autoFocusOk={!hasErrors}
        okDisabled={hasErrors}
        open={open && !customerApprovalDialogOpen}
        title={intl.formatMessage({defaultMessage: "Godkend?"})}
        onCancel={onCancel}
        onOk={setCreateSalesLinesTrue}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    </>
  );
});
