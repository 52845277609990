import {
  CustomerUrl,
  Project,
  ProjectAccess,
  emptyProject as projectDefaults,
} from "@co-common-libs/resources";
import {economicProjectSyncEnabled} from "@co-common-libs/utils";
import {
  DecimalField,
  IntegerField,
  MinutesField,
  OfflineWarning,
  ProjectAccessSelect,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  getCurrentUserCanAddProject,
  getCurrentUserCanEditProject,
  getCustomerLookup,
  getCustomerSettings,
} from "@co-frontend-libs/redux";
import {useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {useIntegrationCustomerSettings} from "integration-hooks";
import React, {useCallback} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {useIsOnline} from "react-use-is-online";

export type ProjectData = Readonly<
  Pick<
    Project,
    | "access"
    | "alias"
    | "customer"
    | "distanceInKm"
    | "name"
    | "projectNumber"
    | "travelTimeInMinutes"
  >
>;

interface ProjectCreateEditDialogProps {
  data: CustomerUrl | Readonly<Project>;
  onCancel: () => void;
  onOk: (data: ProjectData) => void;
  open: boolean;
}
// TODO(mr): refactor
//            - <ResourceName>CreateDialog
//            - <ResourceName>EditDialog
//            - <ResourceName>CreateWithIntegrationDialog (add to lib in economy-system-integration feature folder)
//            - <ResourceName>EditWithIntegrationDialog (add to lib in economy-system-integration feature folder)

export const ProjectCreateEditDialog = React.memo(function ProjectCreateEditDialog(
  props: ProjectCreateEditDialogProps,
): JSX.Element | null {
  const {data, onCancel, onOk, open} = props;

  const intl = useIntl();
  const customerSettings = useSelector(getCustomerSettings);
  const customerLookup = useSelector(getCustomerLookup);
  const currentUserCanAddProject = useSelector(getCurrentUserCanAddProject);
  const currentUserCanEditProject = useSelector(getCurrentUserCanEditProject);

  const project = typeof data !== "string" ? data : undefined;
  const customerUrl = typeof data === "string" ? data : data.customer;
  const customer = customerLookup(customerUrl);

  const [access, setAccess] = useResettingState<ProjectAccess>(
    project?.access ?? projectDefaults.access,
    open,
  );
  const [alias, setAlias] = useResettingState(project?.alias ?? projectDefaults.alias, open);
  const [distanceInKm, setDistanceInKm] = useResettingState(
    project?.distanceInKm ?? projectDefaults.distanceInKm,
    open,
  );
  const [name, setName] = useResettingState(project?.name ?? projectDefaults.name, open);
  const [projectNumber, setProjectNumber] = useResettingState(
    project?.projectNumber ?? projectDefaults.projectNumber,
    open,
  );
  const [travelTimeInMinutes, setTravelTimeInMinutes] = useResettingState(
    project?.travelTimeInMinutes ?? projectDefaults.travelTimeInMinutes,
    open,
  );

  const {
    c5Sync,
    canAddProject,
    canEditProjectDistance,
    canEditProjects: canEditProjectNumberAndName,
    economicSync,
    enableProjectDistance,
    enableProjects,
    enableProjectTravelTime,
    navSync,
    navSyncProfile,
    projectLabelVariant,
    showProjectAlias,
  } = customerSettings;

  const editMode = project !== undefined;

  const createTitle =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Opret projekt"})
      : intl.formatMessage({defaultMessage: "Opret sag"});

  const editTitle =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Rediger projekt"})
      : intl.formatMessage({defaultMessage: "Rediger sag"});

  const title = editMode ? editTitle : createTitle;

  const handleOk = useCallback(
    () =>
      onOk({
        access,
        alias,
        customer: customerUrl,
        distanceInKm,
        name,
        projectNumber,
        travelTimeInMinutes,
      }),
    [access, alias, customerUrl, distanceInKm, name, onOk, projectNumber, travelTimeInMinutes],
  );

  const handleProjectNumberChange = useCallback(
    (value: number | string | null): void => {
      setProjectNumber(`${value ?? ""}`);
    },
    [setProjectNumber],
  );

  const {isOnline} = useIsOnline();
  const {integrationStatus} = useIntegrationCustomerSettings();
  const noSync = !integrationStatus.hasActiveEconomySystemIntegration;
  const econProjectSync = economicProjectSyncEnabled(customerSettings);
  const c5ProjectSync = enableProjects && c5Sync;
  const navProjectSync =
    enableProjects && navSync && (navSyncProfile === "dme" || navSyncProfile === "hvt");

  if ((editMode && !currentUserCanEditProject) || (!editMode && !currentUserCanAddProject)) {
    return null;
  }

  return (
    <ResponsiveDialog
      okDisabled={!projectNumber.trim() || !name.trim() || !isOnline}
      open={open}
      title={title}
      onCancel={onCancel}
      onOk={handleOk}
    >
      <DialogContent>
        <OfflineWarning />
        {customer ? (
          <FormattedMessage
            defaultMessage="Kunde: {customer}"
            tagName="h4"
            values={{customer: customer.name}}
          />
        ) : null}
        <ProjectAccessSelect
          access={access}
          disabled={!(noSync || (econProjectSync && (access === "open" || access === "hidden")))}
          onChange={setAccess}
        />
        {economicSync ? (
          <IntegerField
            fullWidth
            required
            autoFocus={!project}
            label={
              projectLabelVariant === "PROJECT"
                ? intl.formatMessage({
                    defaultMessage: "Projektnummer",
                  })
                : intl.formatMessage({
                    defaultMessage: "Sagsnummer",
                  })
            }
            margin="dense"
            readonly={
              c5ProjectSync ||
              navProjectSync ||
              econProjectSync ||
              (editMode ? !canEditProjectNumberAndName : !canAddProject)
            }
            value={projectNumber ? parseInt(projectNumber) : null}
            onChange={handleProjectNumberChange}
          />
        ) : (
          <TrimTextField
            fullWidth
            required
            autoFocus={!project}
            label={
              projectLabelVariant === "PROJECT"
                ? intl.formatMessage({
                    defaultMessage: "Projektnummer",
                  })
                : intl.formatMessage({
                    defaultMessage: "Sagsnummer",
                  })
            }
            margin="dense"
            readonly={
              c5ProjectSync ||
              navProjectSync ||
              econProjectSync ||
              (editMode ? !canEditProjectNumberAndName : !canAddProject)
            }
            value={projectNumber}
            variant="outlined"
            onChange={handleProjectNumberChange}
          />
        )}
        <TrimTextField
          fullWidth
          required
          label={intl.formatMessage({defaultMessage: "Navn"})}
          margin="dense"
          readonly={
            c5ProjectSync ||
            navProjectSync ||
            econProjectSync ||
            (editMode ? !canEditProjectNumberAndName : !canAddProject)
          }
          value={name}
          variant="outlined"
          onChange={setName}
        />
        {showProjectAlias ? (
          <TrimTextField
            fullWidth
            label={intl.formatMessage({defaultMessage: "Søgenavn"})}
            margin="dense"
            readonly={c5ProjectSync || navProjectSync}
            value={alias}
            variant="outlined"
            onChange={setAlias}
          />
        ) : null}
        {enableProjectDistance ? (
          <DecimalField
            fullWidth
            decimalPlaces={2}
            label={intl.formatMessage({defaultMessage: "Afstand (km)"})}
            margin="dense"
            maxDigits={6}
            readonly={!canEditProjectDistance}
            value={distanceInKm}
            onChange={setDistanceInKm}
          />
        ) : null}
        {enableProjectTravelTime ? (
          <MinutesField
            fullWidth
            label={intl.formatMessage({
              defaultMessage: "Rejsetid, en vej (timer:minutter)",
            })}
            value={travelTimeInMinutes}
            onChange={setTravelTimeInMinutes}
          />
        ) : null}
      </DialogContent>
    </ResponsiveDialog>
  );
});
