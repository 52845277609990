import {
  PriceGroup,
  PriceGroupItem,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  ProductUrl,
  ResourceTypeUnion,
  emptyPriceItem,
} from "@co-common-libs/resources";
import {
  ImportPreviewProduct,
  sortPriceItemUseListByLineNumber,
  sortPriceItemUseListByManualOrder,
  sortPriceItemUseListByRemoteURL,
  sortPriceItemUseListByUnitAndName,
} from "@co-common-libs/resources-utils";
import {
  ErrorDialog,
  MultiplePriceItemsDialog,
  SingleTextFieldDialog,
  SpinnerDialog,
} from "@co-frontend-libs/components";
import {
  actions,
  getCustomerSettings,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductLookup,
  getUnitLookup,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
} from "@co-frontend-libs/utils";
import {Fab, IconButton, Theme, makeStyles} from "@material-ui/core";
import {importResourceUrl} from "api-endpoint-urls";
import {ProductCreateEditDialog} from "app-components";
import {ImportProductPriceItemsDialog} from "feat-import-resources";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {integrationCustomerSettings} from "shared-integration-customer-settings";
import {v4 as uuid} from "uuid";

const useStyles = makeStyles((theme: Theme) => ({
  consultantFab: {
    "&:hover": {
      backgroundColor: theme.palette.consultant.dark,
    },
    backgroundColor: theme.palette.consultant.main,
  },
}));

// TODO(mr): split into more focussed components
//             - import price item FAB and IconButton
//             - select price item FAB and IconButton
//           the import price item FAB and IconButton components should be placed in
//           `libs/feature/economy-system-integration/frontend/import-resources/feat-import-resources`
//
export const AddPriceItemToPriceGroup = React.memo(function AddPriceItemToPriceGroup({
  priceGroupUrl,
  selectedPriceItems,
  showAddCoPriceitemFab,
  showAsFab,
}: {
  priceGroupUrl: PriceGroupUrl;
  selectedPriceItems?: string[];
  showAddCoPriceitemFab?: boolean;
  showAsFab?: boolean;
}): JSX.Element {
  const priceItemArray = useSelector(getPriceItemArray);
  const customerSettings = useSelector(getCustomerSettings);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const unitLookup = useSelector(getUnitLookup);
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const [importOrSelectPriceItemsDialogOpen, setImportOrSelectPriceItemsDialogOpen] =
    useState(false);
  const setImportPriceItemsDialogOpenTrue = useCallWithTrue(setImportOrSelectPriceItemsDialogOpen);
  const setImportPriceItemsDialogOpenFalse = useCallWithFalse(
    setImportOrSelectPriceItemsDialogOpen,
  );
  const setSelectPriceItemsDialogOpenTrue = useCallWithTrue(setImportOrSelectPriceItemsDialogOpen);
  const setSelectPriceItemsDialogOpenFalse = useCallWithFalse(
    setImportOrSelectPriceItemsDialogOpen,
  );

  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const setProductDialogOpenFalse = useCallWithFalse(setProductDialogOpen);

  const [createPriceItemDialogOpen, setCreatePriceItemDialogOpen] = useState(false);
  const setCreatePriceItemDialogOpenTrue = useCallWithTrue(setCreatePriceItemDialogOpen);
  const setCreatePriceItemDialogOpenFalse = useCallWithFalse(setCreatePriceItemDialogOpen);

  // TODO(mr): make shared between backend/frontend so that price item import also return updated price item group
  //           still required by frontend for CO-only price items
  const addPriceItemsToPriceGroup = useCallback(
    (priceItemURLs: ReadonlySet<PriceItemUrl>) => {
      const selectedPriceGroup = priceGroupLookup(priceGroupUrl) as PriceGroup;
      const oldPriceGroupItemSet = selectedPriceGroup.priceGroupItemSet;

      let newPriceGroupItemSet = oldPriceGroupItemSet.slice();
      const {brugerdataSync, c5Sync, economicSync, navSync} = customerSettings;

      if (economicSync || (!c5Sync && !navSync && !brugerdataSync)) {
        const sortedPriceItemUrls = _.orderBy([...priceItemURLs], (url) => {
          const priceItem = priceItemLookup(url);
          return priceItem?.remoteUrl;
        });
        const oldMaxOrder = _.max(newPriceGroupItemSet.map((p) => p.order)) || 0;
        sortedPriceItemUrls.forEach((priceItemURL, index) => {
          if (!newPriceGroupItemSet.some((p) => p.priceItem === priceItemURL)) {
            newPriceGroupItemSet.push({
              order: oldMaxOrder + index + 1,
              priceItem: priceItemURL,
            });
          }
        });
      } else {
        priceItemURLs.forEach((priceItemURL) => {
          if (!newPriceGroupItemSet.some((p) => p.priceItem === priceItemURL)) {
            newPriceGroupItemSet.push({
              order: 0,
              priceItem: priceItemURL,
            });
          }
        });
      }
      newPriceGroupItemSet = sortPriceItemUseListByUnitAndName(
        newPriceGroupItemSet,
        priceItemLookup,
        unitLookup,
      );

      const priceGroupSet = new Set([{priceGroupItemSet: newPriceGroupItemSet}]);
      if (economicSync || (!c5Sync && !navSync && !brugerdataSync)) {
        newPriceGroupItemSet = sortPriceItemUseListByManualOrder(
          newPriceGroupItemSet,
          priceGroupSet,
        );
      } else if (navSync) {
        newPriceGroupItemSet = sortPriceItemUseListByLineNumber(
          newPriceGroupItemSet,
          priceItemLookup,
        );
      } else {
        newPriceGroupItemSet = sortPriceItemUseListByRemoteURL(
          newPriceGroupItemSet,
          priceItemLookup,
        );
      }
      const newPriceGroupItemSetWithOrder: PriceGroupItem[] = [];
      let previousOrder = -1;
      for (const priceGroupItem of newPriceGroupItemSet) {
        const priceGroupItemWithOrder =
          priceGroupItem.order > previousOrder
            ? priceGroupItem
            : {...priceGroupItem, order: previousOrder + 1};
        newPriceGroupItemSetWithOrder.push(priceGroupItemWithOrder);
        previousOrder = priceGroupItemWithOrder.order;
      }
      if (!_.isEqual(oldPriceGroupItemSet, newPriceGroupItemSetWithOrder)) {
        dispatch(
          actions.update(selectedPriceGroup.url, [
            {
              member: "priceGroupItemSet",
              value: newPriceGroupItemSetWithOrder,
            },
          ]),
        );
      }
    },
    [customerSettings, dispatch, priceGroupLookup, priceGroupUrl, priceItemLookup, unitLookup],
  );

  const handlePriceItemDialogOk = useCallback(
    (priceItemURLs: ReadonlySet<PriceItemUrl>) => {
      setImportOrSelectPriceItemsDialogOpen(false);
      addPriceItemsToPriceGroup(priceItemURLs);
    },
    [addPriceItemsToPriceGroup],
  );

  const [spinnerDialogOpen, setSpinnerDialogOpen] = useState(false);

  const handleErrorMessageOk = useCallback(() => setErrorMessage(""), []);

  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleImportProductsCOPriceItemDialogOk = useCallback(
    async (importProducts: ImportPreviewProduct[], priceItems: PriceItem[]) => {
      setImportOrSelectPriceItemsDialogOpen(false);
      setSpinnerDialogOpen(true);
      try {
        const response = await jsonFetch(importResourceUrl("priceItem"), "POST", {
          products: importProducts.map((p) => ({
            catalogNumber: p.identifier,
            groupIdentifier: p.group,
            name: p.name,
            price: p.price,
            remoteUrl: p.remoteUrl,
            unit: p.unit?.remoteUrl || null,
          })),
        });

        if (response.data) {
          // product groups, products and price items
          const importedData: ResourceTypeUnion[] = response.data;

          dispatch(actions.addToOffline(importedData));

          const newOrUpdatedPriceItemUrls = importedData
            .filter(
              // TODO(mr) move resourcePathMapping to be shared between backend/frontend instead of hardcoding
              ({url}) => url.includes("priceitems/"),
            )
            .map(({url}) => url as PriceItemUrl);

          const prevPriceItemUrls = priceItems.map(({url}) => url);

          addPriceItemsToPriceGroup(new Set([...prevPriceItemUrls, ...newOrUpdatedPriceItemUrls]));
        }
      } catch (error) {
        setErrorMessage(translateNetworkError(error, intl));
      }

      setSpinnerDialogOpen(false);
    },
    [addPriceItemsToPriceGroup, dispatch, intl],
  );

  const productLookup = useSelector(getProductLookup);

  const handleAddProductDialogOk = useCallback(
    (productUrl: ProductUrl) => {
      setProductDialogOpen(false);
      const product = productLookup(productUrl);
      if (!product) {
        return;
      }

      const id = uuid();
      const url = instanceURL("priceItem", id);
      const newPriceItem: PriceItem = {
        ...emptyPriceItem,
        active: true,
        billable: true,
        id,
        name: product.name,
        product: productUrl,
        relatedUnit: product.relatedUnit,
        remoteUrl: product.remoteUrl,
        url,
      };
      dispatch(actions.create(newPriceItem));
      addPriceItemsToPriceGroup(new Set([url]));
      dispatch(actions.go("/settings/priceitem/:id", {id}));
    },
    [productLookup, dispatch, addPriceItemsToPriceGroup],
  );

  const handleAddCOPriceItemDialogOk = useCallback(
    (name: string) => {
      setCreatePriceItemDialogOpen(false);
      const id = uuid();
      const url = instanceURL("priceItem", id);
      const data: PriceItem = {
        ...emptyPriceItem,
        active: true,
        billable: !customerSettings.canEditPriceItemProduct,
        id,
        name,
        url,
      };

      dispatch(actions.create(data));
      addPriceItemsToPriceGroup(new Set([url]));
      dispatch(actions.go("/settings/priceitem/:id", {id}));
    },
    [customerSettings.canEditPriceItemProduct, dispatch, addPriceItemsToPriceGroup],
  );

  const handlePriceItemAdd = useCallback(() => {
    setImportOrSelectPriceItemsDialogOpen(false);
    if (customerSettings.economicSync) {
      setProductDialogOpen(true);
    } else {
      setCreatePriceItemDialogOpen(true);
    }
  }, [customerSettings.economicSync]);

  const {priceItems} = integrationCustomerSettings(customerSettings);

  return (
    <>
      {showAddCoPriceitemFab ? (
        <Fab
          className={classes.consultantFab}
          size="small"
          style={{
            position: "absolute",
            right: 60,
            top: 16,
          }}
          onClick={setCreatePriceItemDialogOpenTrue}
        >
          <PlusIcon />
        </Fab>
      ) : null}
      {showAsFab ? (
        <Fab
          size="small"
          style={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
          onClick={
            priceItems.canImport
              ? setImportPriceItemsDialogOpenTrue
              : setSelectPriceItemsDialogOpenTrue
          }
        >
          <PlusIcon />
        </Fab>
      ) : (
        <IconButton
          style={{float: "right"}}
          onClick={
            priceItems.canImport
              ? setImportPriceItemsDialogOpenTrue
              : setCreatePriceItemDialogOpenTrue
          }
        >
          <PlusIcon />
        </IconButton>
      )}

      {priceItems.canImport ? (
        <>
          <ImportProductPriceItemsDialog
            key="select-multiple-economic-products-co-price-items-dialog"
            addLabel={intl.formatMessage({
              defaultMessage: "Opret prislinje",
            })}
            open={importOrSelectPriceItemsDialogOpen}
            onAdd={handlePriceItemAdd}
            onCancel={setImportPriceItemsDialogOpenFalse}
            onOk={handleImportProductsCOPriceItemDialogOk}
          />
          <ProductCreateEditDialog
            createForPriceItem
            forPriceGroupUrl={priceGroupUrl}
            open={productDialogOpen}
            onCancel={setProductDialogOpenFalse}
            onOk={handleAddProductDialogOk}
          />
        </>
      ) : (
        <MultiplePriceItemsDialog
          key="select-multiple-price-items-dialog"
          open={importOrSelectPriceItemsDialogOpen}
          priceItemArray={priceItemArray.filter(
            (w) => !selectedPriceItems?.includes(w.url || `${w.c5_recid}`) && w.active,
          )}
          unitLookup={unitLookup}
          onCancel={setSelectPriceItemsDialogOpenFalse}
          onOk={handlePriceItemDialogOk}
        />
      )}
      <SingleTextFieldDialog
        label={intl.formatMessage({defaultMessage: "Navn"})}
        open={createPriceItemDialogOpen}
        title={intl.formatMessage({defaultMessage: "Ny prislinie"})}
        onCancel={setCreatePriceItemDialogOpenFalse}
        onOk={handleAddCOPriceItemDialogOk}
      />
      <SpinnerDialog
        open={spinnerDialogOpen}
        title={intl.formatMessage({defaultMessage: "Opretter prislinjer"})}
      />
      <ErrorDialog
        message={errorMessage}
        open={!!errorMessage}
        title={intl.formatMessage({
          defaultMessage: "Oprettelse af prislinjer fejlede",
        })}
        onOk={handleErrorMessageOk}
      />
    </>
  );
});
