import {getCurrentRole} from "@co-frontend-libs/redux";
import {Tab, Tabs} from "@material-ui/core";
import {MenuToolbar, PageLayout} from "app-components";
import {useQueryParameterState} from "app-utils";
import {useIntegrationCustomerSettings} from "integration-hooks";
import React, {useCallback} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ExportContent} from "./export/export-content";
import {ImportContent} from "./import/import-content";

interface CsvImportAndExportPageProps {
  onMenuButton: (event: React.MouseEvent) => void;
}

type TabValue = "export" | "import";

export const CsvImportAndExportPage = React.memo(function CsvImportAndExportPage(
  props: CsvImportAndExportPageProps,
): JSX.Element {
  const {onMenuButton} = props;
  const intl = useIntl();

  const {csv} = useIntegrationCustomerSettings();

  const role = useSelector(getCurrentRole);
  const csvImportEnabled = csv.canImport(role);
  const csvExportEnabled = csv.canExport(role);
  const showTabs = csvExportEnabled && csvImportEnabled;

  const [activeTab, setActiveTab] = useQueryParameterState<TabValue>(
    "tab",
    csvImportEnabled ? "import" : "export",
  );

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: TabValue) => {
      setActiveTab(value);
    },
    [setActiveTab],
  );

  const tabs = showTabs ? (
    <Tabs scrollButtons="auto" value={activeTab} variant="scrollable" onChange={handleTabChange}>
      <Tab label={intl.formatMessage({defaultMessage: "Import"})} value="import" />
      <Tab label={intl.formatMessage({defaultMessage: "Eksport"})} value="export" />
    </Tabs>
  ) : undefined;

  const title =
    csvExportEnabled && csvImportEnabled
      ? intl.formatMessage({defaultMessage: "Import og eksport"})
      : csvExportEnabled
        ? intl.formatMessage({defaultMessage: "Eksport"})
        : intl.formatMessage({defaultMessage: "Import"});

  let content: JSX.Element | undefined;
  if (activeTab === "import" && csvImportEnabled) {
    content = <ImportContent />;
  } else if (activeTab === "export" && csvExportEnabled) {
    content = <ExportContent />;
  }

  return (
    <PageLayout
      withPadding
      tabs={tabs}
      toolbar={<MenuToolbar title={title} onMenuButton={onMenuButton} />}
    >
      {content}
    </PageLayout>
  );
});
