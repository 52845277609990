import {
  useBooleanQueryParameterState,
  useNullableQueryParameterState,
  useQueryParameterState,
} from "app-utils";
import {useCallback} from "react";

interface ArchiveSearchState {
  fromDate: string | null;
  handleFromDateBlur: () => void;
  handlePeriodStartSelectedInDialog: (date: string | null) => void;
  searchPerformedWithCurrentCriteria: boolean;
  setFromDate: (date: string | null) => void;
  setSearchPerformedWithCurrentCriteria: (value: boolean) => void;
  setTextSearch: (value: string) => void;
  setToDate: (date: string | null) => void;
  textSearch: string;
  toDate: string | null;
}

export function useArchiveSearchState(): ArchiveSearchState {
  const [fromDate, setFromDate] = useNullableQueryParameterState("archiveFromDate", null);
  const [toDate, setToDate] = useNullableQueryParameterState("archiveToDate", null);
  const [textSearch, setTextSearch] = useQueryParameterState<string>("search", "");
  const [searchPerformedWithCurrentCriteria, setSearchPerformedWithCurrentCriteria] =
    useBooleanQueryParameterState("searchPerformedWithCurrentCriteria");

  const handleFromDateBlur = useCallback(() => {
    if (fromDate && (!toDate || fromDate > toDate)) {
      setToDate(fromDate);
    }
  }, [fromDate, setToDate, toDate]);

  const handlePeriodStartSelectedInDialog = useCallback(
    (date: string | null) => {
      if (date) {
        if (!toDate || date > toDate) {
          setFromDate(date);
          setToDate(date);
        } else {
          setFromDate(date);
        }
      }
    },
    [setFromDate, setToDate, toDate],
  );

  return {
    fromDate,
    handleFromDateBlur,
    handlePeriodStartSelectedInDialog,
    searchPerformedWithCurrentCriteria,
    setFromDate,
    setSearchPerformedWithCurrentCriteria,
    setTextSearch,
    setToDate,
    textSearch,
    toDate,
  };
}
