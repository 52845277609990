import {RelevantConfigPart} from "./relevant-config-part";

export enum ActiveEconomySystemIntegrationKind {
  NONE,
  LEGACY,
  IMPORT_ONLY,
  LIVE_SYNC,
}

export function getEconomySystemIntegrationKind(
  config: RelevantConfigPart,
): ActiveEconomySystemIntegrationKind {
  const {brugerdataSync, c5Sync, economicSync, navSync, unicontaSync} = config;

  const legacy = c5Sync || navSync || brugerdataSync;
  const importOnly = unicontaSync;
  // noinspection UnnecessaryLocalVariableJS
  const liveSync = economicSync;

  if (legacy) {
    return ActiveEconomySystemIntegrationKind.LEGACY;
  } else if (importOnly) {
    return ActiveEconomySystemIntegrationKind.IMPORT_ONLY;
  } else if (liveSync) {
    return ActiveEconomySystemIntegrationKind.LIVE_SYNC;
  } else {
    return ActiveEconomySystemIntegrationKind.NONE;
  }
}
