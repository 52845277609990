import {urlToId} from "@co-common-libs/resources";
import {OrderArchiveData} from "@co-common-libs/resources-utils";
import {DateField, TrimTextField} from "@co-frontend-libs/components";
import {actions} from "@co-frontend-libs/redux";
import {NetworkError, StatusError} from "@co-frontend-libs/utils";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  OrderTableColumnID,
  PaginationPageSize,
  SortingState,
  TableDefaults,
  buildArchiveDataUrl,
  useArchiveSearchState,
  useTableWithPagination,
} from "app-components";
import {useFetchGet} from "app-utils";
import bowser from "bowser";
import clsx from "clsx";
import React, {useCallback, useEffect, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch} from "react-redux";
import {ArchiveOrderTable} from "./archive-order-table";

const mobile = bowser.tablet || bowser.mobile;

export const useStyles = makeStyles((_theme: Theme) => {
  return createStyles({
    mobilePadding: {padding: "1em 11px 1em 11px"},
    pcPadding: {padding: "1em"},
  });
});

const orderArchiveTableDefaults: TableDefaults<OrderTableColumnID> = {
  defaultRowsPerPage: PaginationPageSize.SMALL,
  defaultSortDirection: "ASC",
  defaultSortKey: "status",
};

const orderArchiveTableIdentifier = "OrderArchiveTable";

export function OrderArchive({onDataLoaded}: {onDataLoaded: () => void}): JSX.Element {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    fromDate,
    handleFromDateBlur,
    handlePeriodStartSelectedInDialog,
    searchPerformedWithCurrentCriteria,
    setFromDate,
    setSearchPerformedWithCurrentCriteria,
    setTextSearch,
    setToDate,
    textSearch,
    toDate,
  } = useArchiveSearchState();

  const [fetchData, fetchDataCancel, data, fetching, fetchError] = useFetchGet<{
    data: OrderArchiveData[];
    total: number;
  }>();

  const [page, rowsPerPage, sortBy, sortDirection, setPage, setRowsPerPage, toggleSortingKey] =
    useTableWithPagination(
      orderArchiveTableIdentifier,
      orderArchiveTableIdentifier,
      orderArchiveTableDefaults.defaultSortKey,
      orderArchiveTableDefaults.defaultSortDirection,
      orderArchiveTableDefaults.defaultRowsPerPage,
      null,
    );

  const sortingState: SortingState<OrderTableColumnID> = useMemo(() => {
    return {sortDirection, sortKey: sortBy};
  }, [sortDirection, sortBy]);

  useEffect(() => {
    if (data) {
      setSearchPerformedWithCurrentCriteria(true);
      onDataLoaded();
    }
  }, [data, onDataLoaded, setSearchPerformedWithCurrentCriteria]);

  useEffect(() => {
    fetchDataCancel();
    setSearchPerformedWithCurrentCriteria(false);
  }, [fromDate, toDate, textSearch, fetchDataCancel, setSearchPerformedWithCurrentCriteria]);

  const doFetch = useCallback(
    (
      fetchFromDate: string | null,
      fetchToDate: string | null,
      fetchSortingState: SortingState<OrderTableColumnID>,
      fetchPage: number,
      fetchRowsPerPage: number,
      fetchTextSearch: string,
    ) => {
      if (!fetchFromDate || !fetchToDate || fetchFromDate <= fetchToDate) {
        const archiveUrl = buildArchiveDataUrl({
          archiveResource: "order",
          fetchFromDate,
          fetchPage,
          fetchRowsPerPage,
          fetchSortingState,
          fetchTextSearch,
          fetchToDate,
        });
        fetchData(archiveUrl);
      }
    },
    [fetchData],
  );

  const handleFetchClick = useCallback(() => {
    doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch);
  }, [doFetch, fromDate, toDate, sortingState, page, rowsPerPage, textSearch]);

  const handleSubmitOnEnter = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>): void => {
      if (event.key === "Enter") {
        handleFetchClick();
      }
    },
    [handleFetchClick],
  );

  useEffect(() => {
    if (searchPerformedWithCurrentCriteria) {
      doFetch(fromDate, toDate, sortingState, page, rowsPerPage, textSearch);
    }
    // trigger on deep linking and table header click
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingState]);

  const handleChangePage = useCallback(
    (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
      setPage(newPage);
      doFetch(fromDate, toDate, sortingState, newPage, rowsPerPage, textSearch);
    },
    [doFetch, fromDate, rowsPerPage, setPage, sortingState, textSearch, toDate],
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const rows = parseInt(event.target.value);
      setPage(0);
      setRowsPerPage(rows);
      doFetch(fromDate, toDate, sortingState, 0, rows, textSearch);
    },
    [doFetch, fromDate, setPage, setRowsPerPage, sortingState, textSearch, toDate],
  );

  const handleOrderTableClick = useCallback(
    (orderURL: string): void => {
      const id = urlToId(orderURL);
      dispatch(actions.go("/orderEntry/:id", {id}));
    },
    [dispatch],
  );

  let errorText: string | undefined;

  if (fetchError) {
    if (fetchError instanceof NetworkError) {
      errorText = intl.formatMessage({
        defaultMessage: "Kunne ikke kontakte server",
      });
    } else if (fetchError instanceof StatusError) {
      errorText = intl.formatMessage({defaultMessage: "Serverfejl"});
    } else {
      errorText = intl.formatMessage({defaultMessage: "Ukendt fejl"});
    }
  }

  return (
    <div
      className={clsx({
        [classes.mobilePadding]: mobile,
        [classes.pcPadding]: !mobile,
      })}
    >
      <Card>
        <CardContent>
          <FormattedMessage defaultMessage="Periode" />
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                autoFocus={!bowser.ios}
                label={intl.formatMessage({defaultMessage: "Fra"})}
                margin="dense"
                referenceDate={toDate || undefined}
                value={fromDate || null}
                yearSuggestions="DATE_BEFORE"
                onBlur={handleFromDateBlur}
                onChange={setFromDate}
                onKeyDown={handleSubmitOnEnter}
                onSelectedInDialog={handlePeriodStartSelectedInDialog}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DateField
                autoOk
                fullWidth
                label={intl.formatMessage({defaultMessage: "Til"})}
                margin="dense"
                minDate={fromDate || undefined}
                referenceDate={fromDate || undefined}
                value={toDate || null}
                yearSuggestions={fromDate ? "DATE_AFTER" : "DATE_BEFORE"}
                onChange={setToDate}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
            <Grid item xs={12}>
              <TrimTextField
                fullWidth
                label={intl.formatMessage({defaultMessage: "Fritekst"})}
                margin="dense"
                value={textSearch}
                variant="outlined"
                onChange={setTextSearch}
                onKeyDown={handleSubmitOnEnter}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            color="primary"
            disabled={(fromDate && toDate && fromDate > toDate) || fetching}
            variant="contained"
            onClick={handleFetchClick}
          >
            <FormattedMessage defaultMessage="Søg" />
          </Button>
        </CardActions>
      </Card>
      {fetching ? (
        <div style={{marginTop: "1em", textAlign: "center"}}>
          <CircularProgress />
        </div>
      ) : null}
      {data ? (
        <div style={{marginTop: "1em"}}>
          <ArchiveOrderTable
            count={data.total}
            data={data.data}
            page={page}
            rowsPerPage={rowsPerPage}
            sortBy={sortBy}
            sortDirection={sortDirection}
            onClick={handleOrderTableClick}
            onHeaderClick={toggleSortingKey}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      ) : null}
      {errorText ? (
        <Card style={{marginTop: "1em"}}>
          <CardContent>
            <div style={{color: theme.palette.error.main}}>{errorText}</div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
}
